<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
              <a-col :md="6" :sm="24">
                <a-form-item label="抬头名称" prop="titleName">
                  <a-input v-model="queryParam.titleName" @pressEnter="handleQuery"  :maxLength="30" placeholder="请输入抬头名称" allow-clear/>
                </a-form-item>
              </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="抬头类型" prop="titleType">
                <a-select placeholder="请选择类型" style="width: 100%" v-model="queryParam.titleType" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.InvoiceTitleTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="公司税号"   prop="companyTax">
                <a-input v-model="queryParam.companyTax" @pressEnter="handleQuery" :maxLength="30" placeholder="请输入公司税号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['invoice:user:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="invoiceType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.InvoiceTypeEnum" :value="record.invoiceType"/>
        </span>
        <span slot="titleType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.InvoiceTitleTypeEnum" :value="record.titleType"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['invoice:user:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined,true)" v-hasPermi="['invoice:user:edit']">
            {{$t('通用.按钮.修改')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser } from '@/api/invoice/user'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        invoiceType: undefined,
        titleType: undefined,
        titleName: undefined,
        contactPhone: undefined,
        mail: undefined,
        companyTax: undefined,
        companyRegisterAddress: undefined,
        companyRegisterPhone: undefined,
        companyBankName: undefined,
        companyBankNo: undefined,
        isDefault: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
       /* {
          title: '用户id ,主键zb_user.id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '用户名称 ',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '抬头类型',
          dataIndex: 'titleType',
          scopedSlots: { customRender: 'titleType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '抬头名称',
          dataIndex: 'titleName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '联系方式',
          dataIndex: 'contactPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '电子邮箱',
          dataIndex: 'mail',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司税号',
          dataIndex: 'companyTax',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '注册地址',
          dataIndex: 'companyRegisterAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册电话',
          dataIndex: 'companyRegisterPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开户银行',
          dataIndex: 'companyBankName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '银行账号',
          dataIndex: 'companyBankNo',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询发票-发票信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        invoiceType: undefined,
        titleType: undefined,
        titleName: undefined,
        contactPhone: undefined,
        mail: undefined,
        companyTax: undefined,
        companyRegisterAddress: undefined,
        companyRegisterPhone: undefined,
        companyBankName: undefined,
        companyBankNo: undefined,
        isDefault: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('invoice/user/export', {
            ...that.queryParam
          }, `发票-发票信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
